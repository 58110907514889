var $background_parallax = $('.bg-parallax'),
		$window = $(window);

$background_parallax.each(function(){

	var $obj = $(this);

	$window.on('scroll', function() {

		var $windowWidth = $window.width(),
				$windowPosition = $window.scrollTop(),
				$objTop = $obj.offset().top,
				$objPosition = $obj.offset().top - $obj.outerHeight();

		$window.on('resize', function(event) {
			$windowWidth = $window.width();
		}); // resize

		if ( $windowWidth >= 768 && $windowPosition >= $objPosition) {
			var yPos = -( ( $windowPosition - $objTop ) / $obj.data('parallax'));
			var backgroundPosition = '50% '+ yPos + 'px';
			$obj.css('background-position', backgroundPosition );
		} else {
			$obj.css('background-position', 'center' );
		}
	}); // scroll
	$window.trigger('scroll');
}); // each