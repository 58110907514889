var $form = $('#formulario');
$form.validate({
  submitHandler: function(form, event) {
    event.preventDefault();
    var $this = $(form),
    $waiting =  $this.find('.waiting'),
    $success =  $this.find('.success'),
    $error =  $this.find('.error'),
    $response = $this.find('#response');
    console.log('form submit debug');
    $response.css('display', 'block');
    $waiting.css('display', 'block');
    $this.ajaxSubmit({
    success: function(response) {
      $this[0].reset();
      $waiting.css('display', 'none');
      $success.css('display', 'block');
      console.log(response);
    },
    error: function(response) {
      console.log(response);
      $waiting.css('display', 'none');
      $error.css('display', 'block');
    }
    });
    setTimeout(function(){
      $success.css('display', 'none');
      $error.css('display', 'none');
      $response.css('display', 'none')
    }, 10000);
   }
});
$.extend($.validator.messages, {
    required: "Campo obrigatório.",
    email: "E-mail inválido.",
    tel: "Telefone inválido."
});