$(document).ready(function($) {
	var $menu = $('.menu');
	$(window).on('scroll', function(event) {
		if ($(window).scrollTop()>=5) {
			$menu.addClass('active');
		} else {
			$menu.removeClass('active');
		}
	});
	$(window).trigger('scroll');
});