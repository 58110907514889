var myLatLng = {lat: -23.5435770, lng: -46.6483150};

var grayStyles = [
  {
    featureType: "all",
    stylers: [
      { saturation: -90 },
      { lightness: 50 }
    ]
  },
];

// Create a map object and specify the DOM element for display.
var map = new google.maps.Map(document.getElementById('map'), {
  center: myLatLng,
  scrollwheel: false,
  zoom: 15,
  styles: grayStyles
});

// Create a marker and set its position.
var marker = new google.maps.Marker({
  map: map,
  position: myLatLng,
  title: 'Estamos aqui!',
  animation: google.maps.Animation.DROP,
});
var contentString = '<h3 style="color: #4fbcd7;">Patrício Lessa</h3>' + '<p>Rua Marques de Itú - n° 408 - 9º Andar<br>Higienópolis - São Paulo - SP - 01223-000</p>';
var infowindow = new google.maps.InfoWindow({
	content: contentString,
	maxWidth: 700
});
google.maps.event.addListener(marker, 'click', function() {
	infowindow.open(map,marker);
});